c = -> console.log.apply console, arguments
require '../vendor/Marka.min'
helper = require './Helper'
$ = require 'jQuery'
class MenuToggler
    #private static variables

    #-----------------------------------
    constructor: ->
        #private variables
        _$document = $(document)
        _isInited = false
        _$targets = null
        _$siteNav = null
        _$mastHead = null
        _$menuBtn = null
        _$main = null
        _$body = null
        _marka = null
        _clickEvent = helper.getClickEvent()
        #-----------------------------------

        #-----------------------------------
        #public function
        @setup = ->
            _grab()
            _init()
            @

        @reset = ->
            _closeMenu()
            @
        #-----------------------------------


        #-----------------------------------
        #private function
        _grab = ->
            _$body = $('body')
            _$main = $('#main')
            _$mastHead = $('#masthead')
            _$siteNav = $('#site-navigation')
            _$menuBtn = _$mastHead.find('.menu-toggle')

            @
        _init = ->
            _marka = new Marka('.menu-toggle > i')
            # _marka.color('#ffffff')
            _closeMarka()
            _addEvents()
            _isInited = true
            @

        _addEvents = ->
            # _$document.on 'videoloaded.my', _changeColor
            _$document.on 'closeMenu.my', _closeMenu
            _$menuBtn.on _clickEvent, _toggleMenu
            if !helper.isMobile()
                $(window).on 'resize', _resizeHandler

            @

        _removeEvents = ->
            _$menuBtn.off _clickEvent, _toggleMenu
            if !helper.isMobile()
                $(window).off 'resize', _resizeHandler
            @

        _closeMenu = =>
            if !_$body.hasClass('menu-open') then return
            _closeMarka()
            _$body.removeClass('menu-open')
            _$mastHead.removeClass('menu-open')
            _$siteNav.removeClass('menu-open')
            @

        _toggleMenu = =>
            if _$body.hasClass('menu-open')
                _closeMarka()
            else
                _openMarka()
            _$body.toggleClass('menu-open')
            _$mastHead.toggleClass('menu-open')
            _$siteNav.toggleClass('menu-open')
            @

        _closeMarka = ->
            _marka.set('bars')
            @

        _openMarka = ->
            _marka.set('times')
            @

        _changeColor = ->
            # _marka.color('#000000')
            @

        _resizeHandler = (event) ->
            if !helper.isSmallerBreakPoint() && $('body').hasClass('menu-open')
                _closeMenu()
            @


        _destroy = ->
            _removeEvents()
            _isInited = false
            @
        #-----------------------------------

    #public static(prototype) function
    #sampleFunk: ->
    #   something doing
    #   @
    #-----------------------------------

module.exports = MenuToggler