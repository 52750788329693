c = -> console.log.apply console, arguments
$ = require 'jQuery'
helper = require './Helper'
class HomeSlide
    #private static variables
    #
    #-----------------------------------
    constructor: (logoManager) ->
        #private variables
        _logoManager = logoManager
        _$window = $(window)
        _$document = $(document)
        _isInited = false
        _$slideContainer = null
        _$slide = null
        _$slideItem = null
        _$slideItemExceptForSakamoto = null
        _$sakamoto = null
        _$commmons10Img = null
        _$nav = null
        _defaultMargin = 0
        _itemWidth = 0
        _half = 0

        #-------------------------------------------------
        # scroll
        _scrollAccelerator = 0
        _scrollRatio = 0.92
        _scrollSpeed = 5
        _scrollDst = 0
        _enterFrame = null
        _canselFrame = null
        _enterFrameID = 0
        #-------------------------------------------------

        #-------------------------------------------------
        # slide
        _timerID = 0
        _isStopSlide = false
        #-------------------------------------------------

        _bodyMaxWidth = parseInt($('body').css('max-width'))

        #-----------------------------------

        #public function
        @setup = ->
            if !helper.isHome('home') then return
            _grab()
            _init()
            @

        @reset = ->
            if _isInited then _destroy()
            if !helper.isHome('home') then return
            @setup()
        #-----------------------------------

        #private function
        _grab = ->
            _$slideContainer = $('#home-slide-container')
            _$slide = _$slideContainer.find('#home-slide')
            _$slideItem = _$slide.find('.home-slide-item')
            _$slideItemExceptForSakamoto = _$slide.find('.home-slide-item').not('#sakamoto')
            _half = Math.floor(_$slideItem.length / 2) - 1
            _$sakamoto = _$slideItem.filter('#sakamoto')
            _$commmons10Img = $('.title-image')
            _$nav = $('#site-navigation')
            @

        _init = ->
            _$document.on 'pjax.fetch.my', _slideHide
            $(_logoManager).on 'loaded.logoManager', _loadSlideImg
            _logoManager.setup()

            _initEnterFrame()

            _isInited = true
            @

        _addEvents = ->
            _$window.on 'resize', _resizeHandler
            @

        _removeEvents = ->
            _$window.off 'resize', _resizeHandler
            _$document.off 'pjax.fetch.my', _slideHide
            $(_logoManager).off 'loaded.logomanager', _loadSlideImg
            _$slideContainer.off 'mousemove', _mouseMoveHandler
            _$slideContainer.off 'mouseleave', _mouseLeaveHandler
            _stopSlide()
            _stopScroll()
            @

        _initEnterFrame = ->
            _enterFrame = do ->
                raf = window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.oRequestAnimationFrame ||
                window.msRequestAnimationFrame

                if (raf && Function.prototype.bind)
                    return raf.bind(window)
                else
                    return (callback) ->
                        window.setTimeout(callback, 1000 / 60)
                        @

            _canselFrame = do ->
                caf = window.cancelAnimationFrame || window.mozCancelAnimationFrame

                if (caf && Function.prototype.bind)
                    return caf.bind(window)
                else
                    return (requestID) ->
                        window.clearTimeout(requestID)
                        @
            @

        _loadSlideImg = ->

            _centering()

            imgDefferdArray = []

            _$slideItem.find('img').each (i, elem) ->
                dfd = $.Deferred()
                if elem.complete
                    dfd.resolve()
                else $(elem).on 'load', ->
                    dfd.resolve()
                    $(elem).off 'load'
                imgDefferdArray.push(dfd.promise())

            #画像ロード以外に最低3秒待つ
            imgDefferdArray.push(_minShowLogoTimer())

            $.when.apply(null, imgDefferdArray).done ->
                _initSlide()

            @

        _minShowLogoTimer = ->
            dfd = $.Deferred()
            # 3秒待つ
            setTimeout ->
                dfd.resolve()
            , 3000
            return dfd.promise()
            @

        _initSlide = ->
            if !_isInited then return

            $fragmentChildren = _shuffle(_$slideItemExceptForSakamoto)
            _$slide.append($fragmentChildren)
            _$slideItem = _$slide.find('.home-slide-item')
            _$slideItem.eq(_half).after(_$sakamoto)
            _$slideItem = _$slide.find('.home-slide-item')

            logoManager.hideLogo()
            _showSlide()
            _showBlack()

            # scroll
            # _$slideContainer.on 'mousemove', _mouseMoveHandler
            # _$slideContainer.on 'mouseleave', _mouseLeaveHandler
            # _enterFrame(_scroll)

            # slide
            _stopSlide()
            _startSlide()

            _addEvents()
            @

        _centering = ->
            imageSize = _logoManager.getImageSize()
            _$slideItem.find('img').width(imageSize).height(imageSize)
            _adjustSlideMargin()
            _adjustSlideTop()
            @

        _adjustSlideMargin = ->
            $firstItem = _$slideItem.first()
            _$slideContainer.height($firstItem.height())
            _itemWidth = $firstItem.outerWidth()

            _$slide.width(_itemWidth * _$slideItem.length)

            windowWidth = _$window.width()
            margin = (windowWidth - _itemWidth) / 2
            _defaultMargin = margin - (_itemWidth * _half)
            _$slide.css('margin-left', _defaultMargin)
            @

        _adjustSlideTop = ->
            itemHeight = _$slideItem.first().height()
            _$slideContainer.css
                top: parseInt((_$window.height() - itemHeight) / 2)
            @


        _shuffle = ($children) ->
            $fragment = $(document.createDocumentFragment())
            while $children.length
                $fragment.append($children.splice(Math.floor(Math.random() * $children.length), 1)[0]);
            return $fragment.children()

        _showSlide = ->
            _$slideContainer.addClass('showed')
            @

        _showBlack = ->
            _$commmons10Img.find('.title-image-black').addClass('showed')
            _$commmons10Img.find('figure').not('.title-image-black').addClass('hided')
            # _$nav.addClass('colored')
            @

        _hideBlack = ->
            _$commmons10Img.find('.title-image-black').removeClass('showed')
            _$commmons10Img.find('figure').not('.title-image-black').removeClass('hided')
            # _$nav.removeClass('colored')
            @


        _mouseMoveHandler = (event) ->
            mouseX = event.pageX
            $container = $(event.currentTarget)
            containerWidth = $container.width()
            containerWidthHalf = containerWidth / 2
            vector = parseFloat((mouseX - containerWidthHalf) / containerWidthHalf)
            _scrollDst = -(vector * _scrollSpeed) + (_scrollAccelerator * _scrollRatio)
            _scrollAccelerator = _scrollDst
            @

        _scroll = ->
            nowMargin = parseInt(_$slide.css('margin-left'))
            dstMargin = nowMargin + _scrollDst
            _$slide.css('margin-left', dstMargin)

            if dstMargin < _defaultMargin - _itemWidth
                _$slide.append(_$slideItem.first())
                _$slide.css('margin-left', _defaultMargin)
            else if dstMargin > _defaultMargin + _itemWidth
                _$slide.prepend(_$slideItem.last())
                _$slide.css('margin-left', _defaultMargin)
            _$slideItem = _$slide.find('.home-slide-item')
            _enterFrameID = _enterFrame(_scroll)
            @

        _stopScroll = ->
            _canselFrame(_enterFrameID)
            @

        _brakeScroll = ->
            _scrollDst = parseFloat(_scrollDst * 0.98)
            if -0.1 < _scrollDst < 0.1
                _scrollDst = 0
            else
                _enterFrame(_brakeScroll)


            @

        _mouseLeaveHandler = (event) ->
            _brakeScroll()
            @


        #-------------------------------------------------
        #slide系処理
        _nextSlide = ->
            _slide(1)
            @

        _prevSlide = ->
            _slide(-1)
            @

        _slide = (num) ->
            margin = parseInt(_$slide.css('margin-left'))
            _$slide.animate({
                    marginLeft: margin - (num * _itemWidth)
                }, 700, 'easeInOutExpo', ->
                    _$slide.append(_$slideItem.first())
                    _$slide.css('margin-left', margin)
                    _$slideItem = _$slide.find('.home-slide-item')
            )

            @

        _startSlide = ->
            _isStopSlide = false
            _autoSlide()

            @

        _autoSlide = ->
            _timerID = setTimeout ->
                _nextSlide()
                if !_isStopSlide
                    _autoSlide()
            , 1900
            @

        _stopSlide = ->
            _isStopSlide = true
            clearTimeout(_timerID)
            @
        #-------------------------------------------------

        _resizeHandler = ->
            _stopSlide()
            _centering()
            _startSlide()
            @

        _slideHide = ->
            _$slideContainer.removeClass('showed')
            @

        _destroy = ->
            _hideBlack()
            _removeEvents()
            _isInited = false
            @
        #-----------------------------------

        # @setup()


    #public static(prototype) function
    #sampleFunk: ->
    #   something doing
    #   @
    #-----------------------------------

module.exports = HomeSlide