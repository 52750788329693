c = -> console.log.apply console, arguments
helper = require './Helper'
require '../vendor/jquery.easing.1.3'
$ = require 'jQuery'

class ScrollManager
    # static variables

    #-----------------------------------
    constructor: ->
        #private variables
        _$menus = null
        _$scrollTarget = null
        # _breakPoint = helper.getBreakPoint()

        #----------------------------------
        @setup = ->
            @


        #private function
        _grab = ->
            ua = window.navigator.userAgent
            _$scrollTarget = if ua.match(/MSIE/i) or ua.match(/trident\/7/i) or ua.match(/Firefox/i) then $ 'html' else $ 'body'
            @

        _init = =>
            @

        _clickHandler = (event) =>
            event.preventDefault()
            event.stopPropagation()
            $dstElement = $(event.currentTarget).data('scrollTo')
            @scrollTo($dstElement.offset().top, 600, 'easeInOutExpo', ->
                location.hash = $dstElement.attr('id')
            )

            return false

        #-----------------------------------

        @scrollTo = (val, callback='', duration=600, easing='easeInOutExpo') =>
            _$scrollTarget.animate
                scrollTop: val
                , duration, easing, =>
                    if callback != '' && $.isFunction(callback)
                        callback()

        _grab()
        _init()

    #public static(prototype) function

    #-----------------------------------

module.exports = ScrollManager