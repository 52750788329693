'use strict'
c = -> console.log.apply console, arguments
require './vendor/jquery.window-smooth-scroll'

helper = require './lib/Helper'

WPjax = require './lib/WPjax'

MenuToggler = require './lib/MenuToggler'
MenuManager = require './lib/MenuManager'
# MenuFixed = require './lib/MenuFixed'
ScrollManager = require './lib/ScrollManager'
HomeLogoManager = require './lib/HomeLogoManager'
HomeSlide = require './lib/HomeSlide'
HomeBgVideoManager = require './lib/HomeBgVideoManager'
# LazyLoadManager = require './lib/LazyLoadManager'
PeopleLightBox = require './lib/PeopleLightBox'
PeopleFilter = require './lib/PeopleFilter'
TitleImageManager = require './lib/TitleImageManager'

jQuery ($)->

    class Main
        #private static variables
        # _menuManager = null
        #-----------------------------------
        constructor: ->
            #-----------------------------------
            #private variables
            _$document = $(document)
            _objects = {}
            #-----------------------------------

            #-----------------------------------
            #public function
            @setup = ->
                @

            @init = ->
                _addEvents()

                # _defineRequestAnimationFrame()

                #all
                _scrollManager = _objects.scrollManager = new ScrollManager()
                _scrollManager.setup()
                _menuToggler = _objects.menuToggler = new MenuToggler()
                _menuToggler.setup()
                # _menuFixed = _objects.menuFixed = new MenuFixed()
                # _menuFixed.setup()
                _menuManager = _objects.menuManager = new MenuManager()
                _menuManager.setup()

                _titleImageManager = _objects.titleImageManager = new TitleImageManager()
                _titleImageManager.setup()

                _homeLogoManager = _objects.homeLogoManager = new HomeLogoManager()
                # _homeSlide = _objects.homeSlide = new HomeSlide(_homeLogoManager)
                # _homeSlide.setup()
                _homeBgVideoManager = _objects.homeBgVideoManager = new HomeBgVideoManager(_homeLogoManager)
                _homeBgVideoManager.setup()
                # _lazyLoadManager = _objects.lazyLoadManager = new LazyLoadManager()
                # _lazyLoadManager.setup()
                _peopleLightBox = _objects.peopleLightBox = new PeopleLightBox(_scrollManager)
                _peopleLightBox.setup()
                _peopleFilter = _objects.peopleFilter = new PeopleFilter()
                _peopleFilter.setup()


                if !helper.isMobile()
                    #PC用クラス

                    _wPjax = _objects.wPjax = new WPjax()
                    _wPjax.setup()

                    if helper.isSupportedBrowser(['chrome', 'firefox']) && !helper.isWindows()
                        $.windowSmoothScroll
                            velocity: 8
                else
                    # c 'mobile'
                    #mobile用クラス
                    @

                @

            #-----------------------------------

            #-----------------------------------
            #private function
            _addEvents = ->
                _$document.on 'pjax.reset.my', _reset
                @

            _defineRequestAnimationFrame = ->
                win = window
                requestAnimationFrame = win.requestAnimationFrame || win.mozRequestAnimationFrame ||
                              win.webkitRequestAnimationFrame || win.msRequestAnimationFrame

                if requestAnimationFrame
                    win.requestAnimationFrame = requestAnimationFrame
                else
                    win.requestAnimationFrame = (callback) ->
                        win.setTimeout(callback, 1000 / 60)
                @

            _reset = ->
                $.each _objects, ->
                    if(@['reset']) then @reset()

            #-----------------------------------

        #-----------------------------------
        #public static(prototype) function
        #
        #-----------------------------------

#--------------------------------------------------------
#--------------------------------------------------------
#メイン
    mainApp = new Main()
    mainApp.setup()
    mainApp.init()