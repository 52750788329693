c = -> console.log.apply console, arguments
$ = require 'jQuery'
helper = require './Helper'
# require '../vendor/canvas-video-player.js'
#
class BackgroundVideoManager
    #private static variables
    #
    #-----------------------------------
    constructor: (logoManager) ->
        #private variables
        _logoManager = logoManager
        _$window = $(window)
        _$document = $(document)
        _isInited = false
        _$bgVideoContainer = null
        _bgVideoDuration = 0
        _$bgVideo = null
        _$bgVideoSP = null
        _$bgVideoCanvas = null
        _bgVideoInterface = null
        _$fullVideoContainer = null
        _$fullVideo = null
        _$soundButton = null
        _$fullVideoButton = null
        _$colored = null
        _$commmons10Img = null
        _$loader = null
        _clickEvent = helper.getClickEvent()
        #-----------------------------------

        #public function
        @setup = ->
            if _grab() then _init()

            @

        @reset = ->
            if _isInited then _destroy()
            @setup()
        #-----------------------------------

        #private function
        _grab = ->
            _$bgVideoContainer = $('#home-bg-video-container')
            if _$bgVideoContainer.length == 0 then return false

            _$bgVideo = $('#home-bg-video')
            _$bgVideoSP = $('#home-bg-video-sp')
            _$bgVideoCanvas = $('#home-bg-video-canvas')

            _$fullVideoContainer = $('#home-full-video-container')
            _$fullVideo = $('#home-full-video')
            _$soundButton = $('#sound-button', '#masthead')
            _$fullVideoButton = $('#full-video-button', '#main')
            _$colored = $('.home-side-navigation, .main-navigation, #site-logo')
            _$commmons10Img = $('.title-image')

            _$loader = $('#loader')
            if _$loader.length == 0
                _$loader = $('<div>', {'id':'loader', 'class':'loader'}).appendTo('#page')
            @
        _init = ->
            _$loader.addClass('showed')

            if _$soundButton.hasClass('muted')
                _$bgVideo.prop('muted', true)

            _logoManager.setImageSizeRatio(1.5)
            _logoManager.setIsSlide(false)
            _logoManager.setup()

            if helper.isMobile()
                _$bgVideo.remove()
                _$bgVideoSP.on 'canplaythrough', _startBgVideo
                _$bgVideoSP.get(0).load()
                canvasVideoPlayer = new CanvasVideoPlayer({
                    videoSelector: '#home-bg-video-sp'
                    canvasSelector: '#home-bg-video-canvas'
                    framesPerSecond: 60
                })

                _bgVideoInterface = canvasVideoPlayer
            else
                _$bgVideoSP.remove()
                _$bgVideoCanvas.remove()

                _$bgVideo.on 'durationchange', ->
                    _bgVideoDuration = _$bgVideo.prop('duration')
                    _$bgVideo.off 'durationchange'
                    $.when(_isCanplaythrough(), _isEnughBuffer()).then ->
                        _startBgVideo()

                _bgVideoInterface = _$bgVideo.get(0)
                _resizeHandler()

            _addEvents()
            _isInited = true
            @

        _addEvents = ->
            _$window.on 'resize', _resizeHandler
            _$soundButton.on _clickEvent, _toggleBgSound
            _$fullVideoButton.on _clickEvent, _clickFullButtonHandler
            @

        _removeEvents = ->
            _$window.off 'resize', _resizeHandler
            _$soundButton.off _clickEvent, _toggleBgSound
            _$fullVideoButton.off _clickEvent, _clickFullButtonHandler
            @

        _isCanplaythrough = ->
            dfd = new $.Deferred()
            _$bgVideo.on 'canplaythrough', ->
                _$bgVideo.off 'canplaythrough'
                dfd.resolve()
            return dfd.promise()
            @

        _isEnughBuffer = ->
            dfd = new $.Deferred()
            _$bgVideo.on 'progress', (event) ->
                $target = $(event.target)
                if $target.prop('readyState')
                    buffered = $target.prop('buffered').end(0)
                    percent = 100 * buffered / _bgVideoDuration
                    if percent > 3
                        dfd.resolve()
                        _$bgVideo.off 'progress'
            return dfd.promise()
            @

        _startBgVideo = ->
            _$loader.addClass('hided')
            _$bgVideoSP.off 'canplaythrough', _startBgVideo

            $('.menu-toggle', '#masthead').addClass('colored')

            if helper.isMobile()
                _$bgVideoCanvas.addClass('loaded')
            else
                _$bgVideo.addClass('loaded')

            _bgVideoInterface.play()
            @

        _toggleBgSound = ->
            _$soundButton.toggleClass('muted')
            if _$bgVideo.prop('muted')
                _$bgVideo.prop('muted', false)
            else
                _$bgVideo.prop('muted', true)
            @

        _stopBgSound = ->
            _$soundButton.addClass('muted')
            _$bgVideo.prop('muted', true)
            @

        _clickFullButtonHandler = (event) ->
            event.preventDefault()
            event.stopPropagation()
            _toggleBgVideo()
            _toggleFullVideo()
            _toggleColor()

            @

        _toggleBgVideo = ->
            if _$bgVideoContainer.hasClass('hided')
                _showBgVideo()
            else
                _hideBgVideo()
            @

        _showBgVideo = ->
            _$bgVideoContainer.removeClass('hided')
            _bgVideoInterface.play()
            @

        _hideBgVideo = ->
            _stopBgSound()
            _$bgVideoContainer.addClass('hided')
            _bgVideoInterface.pause()
            @

        _toggleFullVideo = ->
            if _$fullVideoContainer.hasClass('showed')
                _hideFullVideo()
            else
                _showFullVideo()
            @

        _showFullVideo = ->
            _$fullVideoContainer.addClass('block').delay(33).queue (next) ->
                _$fullVideoContainer.addClass('showed')
                next()
            @

        _hideFullVideo = ->
            _$fullVideoContainer.removeClass('showed').delay(33).queue (next) ->
                _$fullVideoContainer.removeClass('block')
                next()
            _$fullVideo.get(0).pause()
            @

        _toggleColor = ->
            _$colored.toggleClass('colored')
            _$commmons10Img.find('.title-image-black').toggleClass('showed')
            _$commmons10Img.find('figure').not('.title-image-black').toggleClass('hided')
            @

        _resizeHandler = ->
            $position = _$fullVideo.parent('.home-full-video-position')
            if _$window.height() < 700 && _$window.width() > 1200
                $position.addClass('window-small')
            else
                $position.removeClass('window-small')

            @

        _destroy = ->
            _removeEvents()
            _$bgVideo = null
            _$bgVideoSP = null
            _$bgVideoCanvas = null
            _$fullVideo = null
            _isInited = false
            @
        #-----------------------------------

        # @setup()


    #public static(prototype) function
    #sampleFunk: ->
    #   something doing
    #   @
    #-----------------------------------

module.exports = BackgroundVideoManager