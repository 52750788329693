c = -> console.log.apply console, arguments
$ = require 'jQuery'
helper = require './Helper'
class PeopleFilter
    #private static variables
    #
    #-----------------------------------
    constructor: ->
        #private variables
        _$document = $(document)
        _$window = $(window)
        _isInited = false
        _$filter = null
        _$filterListItem = null
        _$people = null
        _colNum = 3
        # _$info = null
        _$infoListItem = null
        _clickEvent = helper.getClickEvent()
        #-----------------------------------

        #public function
        @setup = ->
            if !helper.isArchive('people') then return
            if _grab() then _init()
            @

        @reset = ->
            if _isInited then _destroy()
            if !helper.isArchive('people') then return
            @setup()
        #-----------------------------------

        #private function
        _grab = ->
            _$filter = $('.filter', '#main')
            if _$filter.length == 0 then return false

            _$filterListItem = _$filter.find('.filter-list-item')
            _$people = $('.hentry', '#main')
            # _$info = $('#info')
            _$infoListItem = $('.info-list-item', '#info')
            @
        _init = ->
            _resizeHandler()
            _addEvents()
            _isInited = true
            @

        _addEvents = ->
            _$filter.find('a').on _clickEvent, _clickHandler
            _$window.on 'resize', _resizeHandler
            @

        _removeEvents = ->
            _$filter.find('a').off _clickEvent, _clickHandler
            _$window.off 'resize', _resizeHandler
            @

        _resizeHandler = ->
            if helper.isSmallerBreakPoint()
                _colNum = 2
            else
                _colNum = 3
            _addClasses()
            @

        _addClasses = ->
            _$people.removeClass('row-start row-end')
            _$people.not('.hided').each (i, elem) ->
                if i % _colNum == 0
                    $(elem).addClass('row-start')
                else if i % _colNum == _colNum - 1
                    $(elem).addClass('row-end')

            _$document.trigger 'filter.my'

            @

        _clickHandler = (event) ->
            event.stopPropagation()
            event.preventDefault()
            $currentTarget = $(event.currentTarget)
            $currentTarget.closest('.filter-list').children('.filter-list-item').removeClass('current')
            $currentTarget.parent('.filter-list-item').addClass('current')

            _initFilters()
            @

        _initFilters = ->
            _$people.removeClass('hided')
            _$infoListItem.removeClass('showed')

            _$filterListItem.filter('.current').each (i, elem) ->
                # filterName = $(elem).find('a').attr('href').match
                filterName = $(elem).find('a').get(0).hash.slice(1)
                if filterName == 'all' then return

                _$people.not('.' + filterName).addClass('hided')
                _$infoListItem.filter('.' + filterName).addClass('showed')

            _addClasses()

            @

        _destroy = ->
            _removeEvents()
            _isInited = false
            @
        #-----------------------------------

        # @setup()


    #public static(prototype) function
    #sampleFunk: ->
    #   something doing
    #   @
    #-----------------------------------

module.exports = PeopleFilter