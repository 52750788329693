c = -> console.log.apply console, arguments
helper = require './Helper'

$ = require 'jQuery'
class MenuManager
    #private static variables
    #
    #-----------------------------------
    constructor: ->
        #private variables
        _$document = $(document)
        _isInited = false
        _$menuItems = null
        _$menuAnchors = null
        _clickEvent = helper.getClickEvent()
        _$moreBtn = null
        _$moreMenu = null
        # _$entries = null
        #-----------------------------------

        #public function
        @setup = ->
            _grab()
            _init()
            @
        #-----------------------------------

        #private function
        _grab = ->
            _$menu = $('#primary-menu')
            _$menuItems = _$menu.find('.menu-item')
            _$menuAnchors = _$menu.find('a')
            _$moreBtn = _$menu.find('.more-button')
            _$moreMenu = _$menu.find('.more-menu')
            @

        _init = ->
            _addEvents()
            _isInited = true
            @

        _addEvents = ->
            _$menuAnchors.on _clickEvent, _clickHandler
            _$moreBtn.on _clickEvent, _showMore
            _$document.on 'pjax.fetch.my', _pjaxFetchHandler
            @

        _removeEvents = ->
            _$menuAnchors.off _clickEvent, _clickHandler
            _$moreBtn.off _clickEvent, _showMore
            _$document.off 'pjax.fetch.my', _pjaxFetchHandler
            @

        _clickHandler = (event) ->
            $target = $(event.currentTarget)
            _setCurrent($target)
            @

        _setCurrent = ($target) ->
            _$menuAnchors.not($target).parent('.menu-item').removeClass('current')
            if !$target.parent('.menu-item').hasClass('current')
                $target.parent('.menu-item').addClass('current')
            @

        _showMore = ->
            _$moreMenu.slideToggle(300)
            @

        _pjaxFetchHandler = (event, locArgs) ->
            if !/people/.test(locArgs.dstLocation.pathname) then return

            _$menuItems.removeClass('current').filter('.people').addClass('current')

            @
        #-----------------------------------

    #public static(prototype) function
    #sampleFunk: ->
    #   something doing
    #   @
    #-----------------------------------

module.exports = MenuManager