c = -> console.log.apply console, arguments
$ = require 'jQuery'
class Helper
    #private static variables
    #
    #-----------------------------------
    constructor: ->
        #private variables
        _ua = null
        _ver = null
        _$body = null
        _breakpoint = 769
        _isInited = false
        _ua = window.navigator.userAgent.toLowerCase()
        _ver = window.navigator.appVersion.toLowerCase()
        _$body = $('body')
        _$window = $(window)

        #-----------------------------------

        #private function
        _setup = ->
            _isInited = true

        _destroy = ->

            @
        #-----------------------------------

        #public function
        @getClickEvent = ->
            if !navigator.msPointerEnabled && window.ontouchstart == null
                #ontouchstartがない場合、nullではなく、undefinedが返る
                return 'touchend'
            else
                return 'click'
            @
        @getBrowser = ->
            ua = _ua
            ver = _ver
            name = 'unknown'

            if ua.indexOf("msie") != -1
                if ver.indexOf("msie 6.") != -1
                    name = 'ie6'
                else if ver.indexOf("msie 7.") != -1
                    name = 'ie7'
                else if ver.indexOf("msie 8.") != -1
                    name = 'ie8'
                else if ver.indexOf("msie 9.") != -1
                    name = 'ie9'
                else if ver.indexOf("msie 10.") != -1
                    name = 'ie10'
                else
                    name = 'ie'
            else if ua.indexOf('trident/7') != -1
                name = 'ie11'
            else if ua.indexOf('chrome') != -1
                name = 'chrome'
            else if ua.indexOf('safari') != -1
                name = 'safari'
            else if ua.indexOf('opera') != -1
                name = 'opera'
            else if ua.indexOf('firefox') != -1
                name = 'firefox'
            return name

        @isSupportedBrowser = (browsers) ->
            thisBrowsers = @getBrowser()
            for i in [0..browsers.length]
                if browsers[i] == thisBrowsers
                    return true
                    exit
            return false

        @getDevices = ->
            ua = _ua
            name = 'unknown'
            if ua.indexOf('iphone') != -1
                name = 'iphone'
            else if ua.indexOf('ipad') != -1
                name = 'ipad'
            else if ua.indexOf('android') != -1
                name = 'android'
            return name
            @

        @isSupportedDevice = (devices) ->
            thisDevices = @getDevices()
            for i in [0..devices.length]
                if devices[i] == thisDevices
                    return true
                    exit
            return false
            @

        @isMobile = ->
            # return _$body.hasClass('mobile')
            return helper.getClickEvent() == 'touchend'

        @isHome = ->
            return _$body.hasClass('home')

        @isIpad = ->
            return _$body.hasClass('ipad')

        @isCategory = (_cat)->
            if _cat
                return _$body.hasClass('category-' + _cat)
            else
                return _$body.hasClass('category')

        @isTag = ->
            return _$body.hasClass('tag')

        @isSingle = ->
            return _$body.hasClass('single')

        @isPage = (pageName)->
            if pageName
                return _$body.hasClass('page') && _$body.hasClass(pageName)
            else
                return _$body.hasClass('page')

        @isArchive = (archiveName) ->
            if archiveName
                return _$body.hasClass('archive') && _$body.hasClass(archiveName)
            else
                return _$body.hasClass('archive')

        @isSearch = ->
            return _$body.hasClass('search')

        @getBreakPoint = ->
            return _breakpoint

        @isSmallerBreakPoint = ->
            return _$window.width() < _breakpoint

        @isWindows = ->
            return _$body.hasClass('windows')
            @

        @isOldIE = ->
            return _$body.hasClass('old-ie')
            @

        @reset = ->
            if _isInited then @_destroy()
            _isInited = false

            _setup()
            @
        #-----------------------------------

    #public static(prototype) function
    #
    #-----------------------------------

helper = new Helper()
module.exports = helper