c = -> console.log.apply console, arguments
$ = require 'jQuery'
helper = require './Helper'
class HomeLogoManager
    #private static variables
    #
    #-----------------------------------
    constructor: ->
        #private variables
        _$window = $(window)
        _isInited = false
        _$siteLogo = null
        _imageSize = 0
        _imageSizeRatio = 1.8
        _imageSizeMax = 600
        _isSlide = true
        _this = this
        #-----------------------------------

        #public function
        @setup = ->
            if _grab() then _init()
            @

        @reset = ->
            if _isInited then _destroy()
            @setup()

        @getImageSize = ->
            return _imageSize
            @
        @hideLogo = ->
            _hideLogo()
            @
        @setImageSizeRatio = (val) ->
            _imageSizeRatio = val
            @
        @setIsSlide = (val) ->
            _isSlide = val
            @
        #-----------------------------------

        #private function
        _grab = ->
            _$siteLogo = $('#site-logo')
            if _$siteLogo.length == 0 then return false

            @
        _init = ->
            $.when(_loadLogoImg()).done ->
                _initLogo()
            _addEvents()
            _isInited = true
            @

        _addEvents = ->
            _$window.on 'resize', _resizeHandler
            @

        _removeEvents = ->
            _$window.off 'resize', _resizeHandler
            @

        _resizeHandler = ->
            _centering()

        _loadLogoImg = ->
            dfd = $.Deferred()
            $logoImg = _$siteLogo.find('img')
            if $logoImg.get(0).complete
                dfd.resolve()
            else
                $logoImg.on 'load', =>
                    dfd.resolve()
                    $logoImg.off 'load'
            return dfd.promise()

        _initLogo = ->
            _centering()
            _$siteLogo.find('.site-logo-image').addClass('showed')
            $(_this).trigger 'loaded.logoManager'

        _centering = ->
            imageSize = _resolveImageSize()
            _adustSiteLogo(imageSize)
            _adjustLogoTop()
            @

        _resolveImageSize = ->
            imageSize = Math.min(parseInt(_$window.width() / _imageSizeRatio), parseInt(_$window.height() / _imageSizeRatio))
            imageSize = Math.min(imageSize, _imageSizeMax)
            _imageSize = imageSize
            return imageSize
            @

        _adustSiteLogo = (imageSize)->

            $siteLogoImage = _$siteLogo.find('.site-logo-image')
            if _isSlide
                $siteScheduleImage = _$siteLogo.find('.site-schedule-image-slide')
            else
                $siteScheduleImage = _$siteLogo.find('.site-schedule-image-video')

            if !$siteScheduleImage.data('defaultSizeRatio')
                $siteScheduleImage.data('defaultSizeRatio', $siteScheduleImage.width() / $siteLogoImage.width())
                if _isSlide
                    $siteScheduleImage.data('defaultBottomRatio', parseFloat($siteScheduleImage.css('bottom')) / $siteScheduleImage.width())
                    $siteScheduleImage.data('defaultMarginRatio', parseFloat($siteScheduleImage.css('margin-left')) / $siteScheduleImage.width())
                else
                    $siteScheduleImage.data('defaultRightRatio', parseFloat($siteScheduleImage.css('right')) / _$window.width())


            $siteLogoImage.width(imageSize).height(imageSize)

            if _isSlide
                $siteScheduleImage.width($siteScheduleImage.data('defaultSizeRatio') * imageSize).css
                    bottom: $siteScheduleImage.data('defaultBottomRatio') * $siteScheduleImage.width()
                    marginLeft: $siteScheduleImage.data('defaultMarginRatio') * $siteScheduleImage.width()
            else
                $siteScheduleImage.css
                    right: $siteScheduleImage.data('defaultRightRatio') * _$window.width()
            @

        _adjustLogoTop = ->
            itemHeight = _$siteLogo.find('img').first().height()
            _$siteLogo.css
                top: parseInt((_$window.height() - itemHeight) / 2)
            @

        _hideLogo = ->
            _$siteLogo.find('.site-logo-image').removeClass('showed')
            @

        _destroy = ->
            _removeEvents()
            _isInited = false
            @
        #-----------------------------------

        # @setup()


    #public static(prototype) function
    #sampleFunk: ->
    #   something doing
    #   @
    #-----------------------------------

module.exports = HomeLogoManager