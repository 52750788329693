c = -> console.log.apply console, arguments
helper = require './Helper'
require '../vendor/jquery.pjax.min'
require '../vendor/jquery.preload.min'
$ = require 'jQuery'
class WPjax
    #
    # jquery pjaxプラグイン falsandtru版
    # jquery preload falsandtru版 に依存
    #

    #private static variables
    #
    #-----------------------------------

    constructor: ->
        #private variables
        _$document = $(document)
        _$window = $(window)
        _$body = null
        _bodyClasses = null
        _$container = null
        _$titleImage = null
        # _$nav = null
        _$links = null
        # _$forms = null
        _$loader = null
        _isCmdKeyDown = false
        _isUrlHash = null
        _isUrlSame = null
        _$scrollTarget = null
        _hostpath = null
        _nowUrl = null
        _dstLocation = null
        #-----------------------------------

        #-----------------------------------
        #public function
        @setup = ->
            _grab()
            _init()
        #-----------------------------------

        #-----------------------------------
        #private function
        _grab = ->
            _$body = $('body')
            _$container = $('#content')
            _$titleImage = $('.title-image-t, .title-image-b')
            _$loader = $('#loader')
            if _$loader.length == 0
                _$loader = $('<div>', {'id':'loader', 'class':'loader'}).appendTo('#page')

            ua = window.navigator.userAgent
            _$scrollTarget = if ua.match(/MSIE/i) or ua.match(/trident\/7/i) or ua.match(/Firefox/i) then $ "html" else $('body')

            _nowUrl = location.href
            @

        _init = ->

            if /touch|tablet|mobile|phone|android|iphone|ipad|blackberry/i.test(window.navigator.userAgent) then return
            _initPreload()
            _initPjax()
            _addEvents()
            @

        _initPreload = ->
            $.preload
                forward: $.pjax.follow
                check: $.pjax.getCache
                encode: true
                ajax:
                    success: ( data, textStatus, XMLHttpRequest ) ->
                        !$.pjax.getCache( this.url ) && $.pjax.setCache( this.url, null, textStatus, XMLHttpRequest )
            @

        _initPjax = ->
            $.pjax
                area: '#body-class-json, #primary, .title-image-t, .title-image-b'
                link: '#page a:not([target])'
                cache:
                    click: true
                    submit: false
                    popstate: true
                ajax:
                    cache: true
                    timeout: 4000

                filter: _pjaxFilter

                callbacks:
                    update:
                        url:
                            before: (event, setting, origLocation, destLocation) =>
                                _nowUrl = $(destLocation).attr('href')
                                @

                        content:
                            before: (event, setting, dstContent, srcContent) =>
                                # ライブラリのバグ　srcContent と dstContentの内容が逆
                                _resetBodyClass(dstContent)

                                # video reset
                                $("video").each ->
                                    @pause()
                                    @src = ""
                                    @load = ""

                                if !helper.isHome()
                                    $(dstContent).find('#main').children().addClass('p-hided p-waited')

                                @

                            after: (event, setting, srcContent, dstContent) =>
                                _$document.trigger 'pjax.reset.my'
                                _afterHandler(dstContent)

                                @

                wait: 300

            @

        _pjaxFilter = ->
            # thisはanchorにapplyされている
            _dstLocation = this

            #hash系は除外artistへのリンクは除く
            if /#.*\/?$/.test(this.href) && !/people/.test(this.href)
                return false

            #同じURLは除外（fetchイベントでキャンセルする）
            if _nowUrl == this.href
                _isUrlSame = true
            else
                _isUrlSame = false

            #コマンドキー押しながらは除外（別タブ開く）
            if _isCmdKeyDown
                return false

            return /^https?:/.test(this.href) && /\/[^.]*$|\.(html?|php)$/.test(this.pathname.replace(/^\/?/, '/'))
            @

        _addEvents = =>

            _$document.on 'pjax:ready', =>
                setTimeout ->
                    _$document.trigger('preload')
                , 2000

            _$document.on 'pjax:fetch', _fetchHandler

            _$window.on 'keydown', _keyDownHandler
            _$window.on 'keyup', _keyUpHandler
            _$window.on 'popstate', _popStateHandler
            @

        _popStateHandler = (event) ->
            @

        _fetchHandler = (event) =>
            if _isCmdKeyDown || _isUrlSame then return false
            _$document.trigger 'pjax.fetch.my', {'dstLocation' : _dstLocation}
            _hideContents()
            _$loader.removeClass('hided').addClass('showed')
            _scrollTop()
            @

        _afterHandler = (dstContent) =>
            _showContents()
            _$loader.removeClass('showed').delay(100).queue ->
                _$loader.addClass('hided').dequeue()
            _resetGoogleAnalytics()

            _$titleImage = $(dstContent).filter('.title-image-t, .title-image-b')
            _resetSNS()
            _hashPageScroll()
            @

        _showContents = ->
            setTimeout ->
                _$container.find('#main').children().removeClass('p-hided p-waited')
            , 200
            @

        _hideContents = ->
            _$titleImage.addClass('p-hided')
            _$container.find('#main').children().addClass('p-hided')
            id = setTimeout ->
                _$titleImage.addClass('p-waited')
                _$container.find('#main').children().addClass('p-waited')
                clearTimeout(id)
            , 200
            @

        _hashPageScroll = ->
            # hash = location.hash
            # if hash
            #     _$scrollTarget.delay(300).animate
            #         scrollTop: $(hash).offset().top - _$nav.height()
            #         queue: false
            #         , 500, 'easeInOutExpo'
            @

        _scrollTop = ->
            _$scrollTarget.animate
                scrollTop: 0
                queue: false
                , 500, 'easeInOutExpo'
            @

        _resetBodyClass = (dstContent)->
            json = JSON.parse($(dstContent).filter('#body-class-json').text())
            _$body.removeClass().addClass json.bodyClass
            _$container.removeClass().addClass 'site-content ' + json.bodyClass
            @

        _keyDownHandler = (event) =>
            if event.keyCode == 91
                _isCmdKeyDown = true
            @

        _keyUpHandler = (event) =>
            if event.keyCode == 91
                _isCmdKeyDown = false
            @

        _resetGoogleAnalytics = ->
            if window.ga
                location = window.location.pathname + window.location.search
                ga('send', 'pageview', location)
            @

        _resetSNS = ->
            if(window.twttr) then twttr.widgets.load()
            if(window.FB) then FB.XFBML.parse()
            if(window.gapi) then gapi.plusone.go()
            @

module.exports = WPjax