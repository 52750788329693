c = -> console.log.apply console, arguments
$ = require 'jQuery'
helper = require './Helper'
class TitleImageManager
    #private static variables
    #
    #-----------------------------------
    constructor: ->
        #private variables
        _$window = $(window)
        _isInited = false
        _$titleImages = null
        _$titleTop = null
        _$banner = null
        _adjustVal = 5
        #-----------------------------------

        #public function
        @setup = ->
            _grab()
            _init()
            @

        # @reset = ->
        #     if _isInited then _destroy()
        #     @setup()
        #-----------------------------------

        #private function
        _grab = ->
            _$titleImages = $('.title-images', '#masthead')

            if _$titleImages.length == 0 then return false
            _$titleTop = _$titleImages.find('.title-image-t')
            _$banner = $('.banner-commmons', '#masthead')
            @
        _init = ->
            _addEvents()
            _isInited = true
            @

        _addEvents = ->
            if !helper.isHome()
                _$window.on 'scroll', _scrollHandler
            @

        _removeEvents = ->
            _$window.off 'scroll', _scrollHandler
            @

        _scrollHandler = ->
            if !helper.isSmallerBreakPoint() then return

            scrollTop = _$window.scrollTop()
            if scrollTop > _$banner.outerHeight(true) - _adjustVal
                _$titleTop.addClass('fixed')
                _$titleImages.height(_$titleTop.height())
            else
                _$titleTop.removeClass('fixed')
                _$titleImages.height('auto')
            @

        _destroy = ->
            _isInited = false
            @
        #-----------------------------------

        # @setup()


    #public static(prototype) function
    #sampleFunk: ->
    #   something doing
    #   @
    #-----------------------------------

module.exports = TitleImageManager