c = -> console.log.apply console, arguments
$ = require 'jQuery'
helper = require './Helper'
class PeopleLightBox
    #private static variables
    #
    #-----------------------------------
    constructor: (scrollManager) ->
        #private variables
        _$window = $(window)
        _$document = $(document)
        _scrollManager = scrollManager
        _isInited = false
        _$pageContent = null
        _$people = null
        _$headers = null
        _$contents = null
        _$closeBtn = null
        _$footer = null
        _adjustFooterTop = 100
        _clickEvent = helper.getClickEvent()
        #-----------------------------------

        #public function
        @setup = ->
            if !helper.isArchive('people') then return
            if _grab() then _init()
            @

        @reset = ->
            if _isInited then _destroy()
            if !helper.isArchive('people') then return
            @setup()
        #-----------------------------------

        #private function
        _grab = ->
            _$people = $('.hentry', '#main')
            if _$people.length == 0 then return false

            _$pageContent = $('.page-content', '#main')
            _$headers = _$people.find('.entry-header')
            _$contents = _$people.find('.entry-content')
            _$closeBtn = _$contents.find('.close-button')
            _$footer = $('#colophon')

            @
        _init = ->
            # _filterHandler()
            _addEvents()
            _initHash()

            _isInited = true
            @

        _initHash = ->
            if !location.hash then return
            $hashTarget = $(location.hash)
            if $hashTarget.length == 0 then return

            window.scrollTo(0, 0)
            $.when(_hashTargetImageLoad($hashTarget), _wait()).done ->
                window.scrollTo(0, 0)
                _scrollManager.scrollTo $hashTarget.position().top - 15, ->
                    $hashTarget.find('.entry-header').trigger(_clickEvent)
            @

        _hashTargetImageLoad = ($hashTarget) ->
            dfd = $.Deferred()
            $img = $hashTarget.find('img')
            if $img.get(0).complete
                dfd.resolve()
            else
                $img.on 'load', ->
                    dfd.resolve()
                    $img.off 'load'
            return dfd.promise()
            @

        _wait = ->
            dfd = $.Deferred()
            setTimeout ->
                dfd.resolve()
            , 1000
            return dfd.promise()
            @

        _addEvents = ->
            _$headers.on 'click', _clickHandler
            _$closeBtn.on _clickEvent, _allClose
            $('#content').on 'click', _allClose
            _$document.on 'filter.my', _filterHandler
            _$document.on 'pjax.reset.my', _initHash
            _$contents.on _clickEvent, (event) ->
                event.stopPropagation()
                event.preventDefault()
            @

        _removeEvents = ->
            _$headers.off 'click', _clickHandler
            _$closeBtn.on _clickEvent, _allClose
            $('#content').off 'click', _allClose
            _$document.off 'filter.my', _filterHandler
            _$document.off 'pjax.reset.my', _initHash
            _$contents.off _clickEvent
            @

        _filterHandler = ->
            _allClose()
            @

        _clickHandler = (event) ->
            event.stopPropagation()
            event.preventDefault()
            $header = $(event.currentTarget)

            if $header.hasClass('showed')
                _allClose()
                return
            else
                _allClose()



            $targetContent = $header.siblings('.entry-content')
            $firstPerson = _$people.not('.hided').first()
            width = $firstPerson.outerWidth(true) + $firstPerson.width()

            #box-sizingがborder-boxのため,css経由でwidthを指定する
            $targetContent.css('width', width)

            $parentEntry = $header.parent('.hentry')
            if $parentEntry.hasClass('row-start')
                left = 0
                right = 'auto'
            else if $parentEntry.hasClass('row-end')
                left = 'auto'
                right = 0
            else
                left = -$firstPerson.width() / 2
                right = 'auto'

            headerHeight = $header.height()
            $targetContent.css
                'top': headerHeight
                'left': left
                'right': right


            $targetContent.addClass('showed')
            $header.addClass('showed')

            targetTop = $targetContent.offset().top
            targetHeight = $targetContent.height()
            footerTop = _$footer.position().top - _adjustFooterTop
            if targetTop + targetHeight > footerTop
                diff = targetTop + targetHeight - footerTop
                _$pageContent.css
                    'padding-bottom': diff
            @

        _allClose = (event) ->
            _$pageContent.css
                'padding-bottom': 0
            _$contents.removeClass('showed')
            _$headers.removeClass('showed')
            @

        _destroy = ->
            _removeEvents()
            _isInited = false
            @
        #-----------------------------------

        # @setup()


    #public static(prototype) function
    #sampleFunk: ->
    #   something doing
    #   @
    #-----------------------------------

module.exports = PeopleLightBox